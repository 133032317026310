body{
	margin: 0 auto !important; 
	float: none !important; 
  font-family: 'calibri', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid{
  padding: 0;
}

a{
  color: #007BFF;
  -o-transition:color .2s ease-out;
  -ms-transition:color .2s ease-out;
  -moz-transition:color .2s ease-out;
  -webkit-transition:color .2s ease-out;
  transition:color .2s ease-out;
}

a:visited{
  color: #007BFF;
}

a:hover{
  color: #005393;
}

#copyright{
  font-size: .8em;
  margin-bottom: 8px ;
}

#row{
  display: flex;
  flex-direction: row;
  width: 260px;
  justify-content: space-between;
}

footer{
  font-size: .85em;
  background-color: #313131;
  color: #FFF;
  height: 100%;
  padding: 10px 5px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

h1{
  color: #003968;
  font-family: acumin-pro,sans-serif;
  font-weight: 700;
  font-style: normal;
}

.h1Home{
  text-align: center;
  font-size: 2.3rem;
  color: #5cb85c;
  font-family: sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

#h1-footer{
  color: #FFF;
  font-size: 1em;
}

h2{
  font-size: 1.5em;
  color: #003968;
  font-family: acumin-pro,sans-serif;
  font-weight: 400;
  font-style: normal;
}

#h2-footer{
  color: #FFF;
  font-size: 1em;
}
  
.h2Home{
  font-size: 2.3rem;
  color: #09b9ff;
  font-family: sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  max-width: 800px;
  text-align: center;
}

.h2West{
  background-color: #e5e5e5;
  text-align: center;
  padding: 5px 0;
}

.h2News{
  /* border-bottom: 1px solid #003968; */
  padding: 5px 0;
  font-size: 1.3em;
}

#h2Products{
  background-color: #e5e5e5;
  color: #003968;
  padding: 10px 0;
  text-align: center;
  margin: 10px 0;
}

.h2Contract{
  color: #000;
  text-align: center;
  font-weight: 600;
  font-size: 1.3em;
  border: 1px solid #DEE2E6;
  padding: 15px 5px;
  margin: 8px 0;

}

h3{
  font-size: 1.3em;
  font-family: acumin-pro,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.h3Rates{
  border-bottom: #000 2px solid;
  padding-bottom: 3px;
}

.bold{
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
}

.pHome{
  color: #FFF;
  font-size: 1.2em;
  text-align: center;
  font-family: sans-serif;
}

.caption{
  font-size: .8em;
  text-align: center;
}

.error{
  text-align: center;
}

.italic{
  font-style: italic;
}

.spinner{
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
  color: #003968;
}

.greyHeader{
  background-color: #e2e2e2;
  padding: 20px 2px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.blueHeader{
  background-color:#003968;
  color:#FFF;
  text-align: center;
  padding: 20px 5px;
  font-size: 1.1em;
}

.blueBackground{
  background-color: #003968;
  color: #FFF;
  padding: 18px 20px 20px 20px;
  margin-bottom: 5px;
}

.fit{
  object-fit: cover;
  object-position: top middle;
  width: 100%;
  height: 700px;
}

.overlay{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.float{
  position: absolute;
  width: 500px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.floatContainer{
  display: flex;
  position: relative;
}

.greyBackground{
  margin-bottom: 10px;
  background-color: #DEE2E6;
  padding: 10px;
  text-align: center;
}

.white{
  color: #FFF;
  border-color: #FFF;
}

.center{
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: block;
}

.ctrBtn{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.banner{
  width: 100%;
  max-width: 1100px;
  display: block;
  margin: 0 auto;
}


.homeBtn{
  display: block;
  width: 300px;
  margin: 10px auto;
}

#homePgBlue{
  background-color: #003968;
  color: #FFF;
  margin-bottom: 2px;
}

#homePgQ{
  padding: 10px;
  text-align: center;
  background-color: #003968;
  color: #FFF;
}

.contactPgText{
  text-align: right;
}

.newsDesc{
  padding: 10px 15px;
  margin: 5px 0;
}

.icon{
  width: 60px;
}

.contactInfo{
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 90%;
}

.map{
  border: 0; 
  height: 300px;
  width: 100%;
  }

.TDACSLogo{
  width: 225px;
  margin: 10px;
}

.pricelist-link {
  text-decoration: none;
  display: inline-block;
  font-size: 1.1em;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 0 20px 10px 20px;
}

.card-title{
  width: 100%;
  display: inline-block;
  color: #003968;
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  cursor: pointer;
  position: relative;
}

@keyframes slide-in {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(50%);
  }
}

.pricelist-link:hover .arrow{
  animation: slide-in 1000ms infinite;
}

.productsCard{
  width: 320px;
  margin: 5px 0 15px 0;
  text-align: center;
}

.contractNotes{
  font-size: .8em;
}

.divRates{
  text-align: center;
  border: 1px solid #DEE2E6;
  padding: 15px 0;
  margin: 8px 0;
}

.pdf{
  width: 100%;
  height: 750px;
}

li::marker{
  color: #003968;
}

.helpDeskContact{
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  padding: 40px 40px 20px 40px;
  font-size: 1em;
}

.helpDeskList{
  padding: 12px;
  margin: 5px;
  font-size: 1.2em;
}

#reportQs{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#reportQs button{
  min-width: 220px;
}

.regQs{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.regQs button{
  min-width: 200px;
  margin: 5px;
}

.paddingTop{
  padding-top: 25px;
}

.marginTop{
  margin-top: 15px;
}

/* Cell Phone Media Queries */
@media only screen and (max-width: 765px) {
 
  .carousel-caption{
    display: flex;
    width: 200px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    position: static;
    padding-bottom: 50px;
  }

  .float{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
    position: static;
  }
  
  .floatContainer{
    display: flex;
    position: relative;
  }

  .hide{
   visibility: invisible;
  }

  .map{
    display: flex;
    justify-content: center;
    width: 350px;
    margin: 0 auto;
  }

  #moboCtr{
    display: flex;
    justify-content: center;
  }

  .moboPad{
    padding-left: 25px;
  }
  
  .productsCard{
    width: 290px;
  }

  #h1Contact{
    padding-left: 0;
    text-align: center;
  }

  #homePgBody{
    margin-left: 0px;
  }
  
  
}

/* Tablet Media Queries */
@media only screen and (min-width: 768px) {

  #WESTDesc{
    width: 600px;
    margin: 0 auto 10px auto;
  }

  .blueBackground{
    padding: 10px;
  } 
  
  .map{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 400px;
  }
  
  #moboCtr{
    display: flex;
    justify-content: center;
  }
  
  .productsCard{
    width: 330px;
  }

  .helpDeskList{
    margin-top: 15px;
  }

  #homePgBody{
    margin-left: 10px;
  }

  #h1Contact{
    padding-left: 1em;
  }
}